// extracted by mini-css-extract-plugin
export const container = "projectPage-module--container--3W3-B";
export const containerGrid = "projectPage-module--containerGrid--3yysa";
export const aboutContainer = "projectPage-module--aboutContainer--al0H_";
export const projectImg = "projectPage-module--projectImg--2Ogi1";
export const projectPhoto = "projectPage-module--projectPhoto--18ywI";
export const projectPhotoFirst = "projectPage-module--projectPhotoFirst--fBG9k";
export const projectPhotoSecond = "projectPage-module--projectPhotoSecond--3q1ck";
export const projectPhotoThird = "projectPage-module--projectPhotoThird--39Btn";
export const aboutText = "projectPage-module--aboutText--1d4_X";
export const featureList = "projectPage-module--featureList---gXvQ";
export const priceCards = "projectPage-module--priceCards--aYsZN";
export const carIcon = "projectPage-module--carIcon--3Kq-s";
export const projectsGrid = "projectPage-module--projectsGrid--3VDdh";
export const projectFiltersContainer = "projectPage-module--projectFiltersContainer--3OCjk";
export const projectFilterText = "projectPage-module--projectFilterText--KGIbx";