/* eslint-disable react/jsx-filename-extension */
import React from "react"
import { graphql } from "gatsby"
import Layout from "./pageLayout"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import * as projectPageStyles from "./projectPage.module.css"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

export default function projectPage({ pageContext, data }) {
  const nodeData = data.mdx
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const customCrumbLabel = nodeData.frontmatter.title

  let imagesBefore = []
  let imagesAfter = []
  let imagesProcess = []
  const currentProject = nodeData.fields.slug
    .replace("/portfolio/", "")
    .replace(/\-/g, "")

  data.allFile.edges.map(project => {
    if (project.node.name.includes(currentProject)) {
      if (project.node.name.includes("before")) {
        imagesBefore.push(project.node)
      } else if (project.node.name.includes("after")) {
        imagesAfter.push(project.node)
      } else {
        imagesProcess.push(project.node)
      }
    }
  })

  return (
    <Layout title={"Project" + " - " + nodeData.frontmatter.title}>
      <div className={projectPageStyles.container}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" > "
          crumbLabel={customCrumbLabel}
        />
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <h1 style={{ textTransform: "uppercase" }}>
            <span
              style={{
                color: "#ff6633",
                fontWeight: 600,
              }}
            >
              PROJECT
            </span>{" "}
            {nodeData.frontmatter.title}
          </h1>
        </ScrollAnimation>
        <MDXProvider>
          <MDXRenderer
            frontmatter={nodeData.frontmatter}
            styles={projectPageStyles}
            imagesBefore={imagesBefore}
            imagesProcess={imagesProcess}
            imagesAfter={imagesAfter}
          >
            {nodeData.body}
          </MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      body
      frontmatter {
        description
        subCategory
        category
        type
        notable
        services
        title
        img {
          childImageSharp {
            fluid(maxWidth: 886, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        absolutePath: { regex: "/images/projects/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 886, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
